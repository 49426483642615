import globalStore from '@/services/global.state';
import {http} from '@utils';

export const sendCode = (userPhone: string) => {
  return http.post('app/sendCode', {userPhone});
};

export const userLogin = (
  userPhone: string,
  code: string,
  userInviteCode?: string,
) => {
  const date = {
    userPhone,
    code,
    userInviteCode,
  };
  if (globalStore.isWeb && !userInviteCode) {
    date.userInviteCode = localStorage.getItem('invitationCode') || undefined;
  }
  return http.post<
    null,
    {
      token: string;
      isNewUser: boolean;
    }
  >('app/userLoginNew', date);
};

export const passwordLogin = (userPhone: string, password: string) => {
  return http.post<null, string>('app/password/login', {
    userPhone,
    password,
  });
};

export const updatePassword = (password: string) => {
  return http.post<null, string>('app/user/set/password', {
    password,
  });
};
