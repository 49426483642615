import {FadeInView} from '@/components/basic/animations';
import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FlatList, ListRenderItemInfo, RefreshControl, View} from 'react-native';
import Text from '@basicComponents/text';
import {useInnerStyle} from './notification.hooks';
import Tag from '@/components/basic/tag';
import {LazyImageBackground} from '@basicComponents/image';
import NoData from '@/components/basic/error-pages/no-data';
import {
  NotificationItem,
  postSystemMessageList,
  postUserMessageList,
} from './notification.service';
import dayjs from 'dayjs';
import TouchableOpacity, {
  NativeTouchableOpacity,
} from '@basicComponents/touchable-opacity';
import globalStore from '@/services/global.state';
import {MessageCountInfo} from '@/services/global.service';
import {goBack, goTo} from '@/utils';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {PageResponse} from '@/types';
import {NoMoreData} from '@/components/basic/default-page';
import {useTranslation} from 'react-i18next';

const NotificationPage = () => {
  const {i18n} = useTranslation();
  const [refreshing, setRefreshing] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [listData, setListData] = useState<NotificationItem[]>([]);
  const pageNo = useRef(1);
  const totalPage = useRef(1);
  const [countInfo, setCountInfo] = useState<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });
  const left = useSharedValue(0);
  const {
    size: {
      listItemImageSize,
      screenHeight,
      screenWidth,
      indicatorWidth,
      tabCount,
    },
    tabStyle,
  } = useInnerStyle();

  const refreshPageInfo = useCallback(async () => {
    console.log('refreshpage', tabIndex, pageNo.current, totalPage.current);
    if (pageNo.current > totalPage.current) {
      return;
    }
    let pageInfo: PageResponse<NotificationItem> = {
      content: [],
      totalPages: 0,
      totalSize: 0,
    };
    if (tabIndex === 0) {
      pageInfo = await postUserMessageList(pageNo.current);
    } else {
      pageInfo = await postSystemMessageList(pageNo.current);
    }
    if (pageNo.current === 1) {
      setListData(pageInfo.content);
    } else {
      setListData([...listData, ...pageInfo.content]);
    }

    totalPage.current = pageInfo.totalPages;
  }, [listData, tabIndex]);

  const refreshNotification = useCallback(
    async (loading = true) => {
      loading && globalStore.globalLoading.next(true);
      try {
        pageNo.current = 1;
        await refreshPageInfo();
      } finally {
        globalStore.globalLoading.next(false);
        setRefreshing(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabIndex],
  );

  const refreshNextPage = () => {
    // 这个情况是加载之前的或者只有一页，不让进行下一页
    if (totalPage.current === 1) {
      return;
    }
    pageNo.current++;
    globalStore.globalLoading.next(true);
    refreshPageInfo().finally(() => globalStore.globalLoading.next(false));
  };

  useEffect(() => {
    const sub = globalStore.notificationSubject.subscribe(_countInfo => {
      setCountInfo(_countInfo);
      refreshNotification(false);
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  useEffect(() => {
    refreshNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  useEffect(() => {
    left.value =
      (screenWidth / tabCount - indicatorWidth) / 2 +
      (tabIndex * screenWidth) / tabCount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex, screenWidth, tabCount, indicatorWidth]);

  const handleChange = (index: number) => {
    // console.log('tabchange');
    setTabIndex(index);
  };
  const activeTitleRender = (
    active: boolean,
    name: string,
    count: number = 0,
  ) => {
    const textRender = active ? (
      <Text second blod fontSize={theme.fontSize.m}>
        {name}
      </Text>
    ) : (
      <Text second fontSize={theme.fontSize.m}>
        {name}
      </Text>
    );
    return (
      <View style={[theme.position.rel]}>
        {textRender}
        {count > 0 ? (
          <Tag content={count} style={[theme.position.abs, tabStyle.tag]} />
        ) : null}
      </View>
    );
  };
  const toDetail = (item: NotificationItem) => {
    globalStore.readNotification.next({
      messageId: item.id,
      messageType: tabIndex === 0 ? 2 : 1,
    });
    globalStore.asyncSetItem('notification-detail', JSON.stringify(item));
    goTo('NotificationDetail');
  };
  const tabItemButtonStyle = [theme.flex.flex1, theme.padding.tbxxl];
  const indicatorStyle = useAnimatedStyle(
    () => ({
      left: withTiming(left.value, {}),
    }),
    [left],
  );
  const renderItem = ({item}: ListRenderItemInfo<NotificationItem>) => {
    return (
      <TouchableOpacity
        style={[theme.margin.btml]}
        onPress={() => toDetail(item)}>
        <View
          key={item.id}
          style={[
            theme.flex.row,
            theme.padding.l,
            theme.flex.alignStart,
            theme.background.white,
            theme.borderRadius.m,
          ]}>
          <LazyImageBackground
            radius={theme.borderRadiusSize.m}
            width={listItemImageSize}
            height={listItemImageSize}
            imageUrl={item.messageIcon}
            style={[theme.margin.rightl]}
          />
          <View
            style={[theme.flex.col, theme.flex.alignStart, theme.flex.flex1]}>
            <Text
              main={item.ifRead === 0}
              secAccent={item.ifRead !== 0}
              fontSize={theme.fontSize.l}
              style={[theme.padding.btmxxs]}>
              {item.messageTitle}
            </Text>
            <Text
              secAccent
              fontSize={theme.fontSize.s}
              style={[theme.padding.btms]}>
              {dayjs(item.sendTime).format('YYYY.MM.DD')}
            </Text>
            <Text
              accent={item.ifRead === 0}
              secAccent={item.ifRead !== 0}
              fontSize={theme.fontSize.s}
              ellipsizeMode="tail"
              numberOfLines={2}>
              {item.messageContent?.replace(/<[^<>]+>/g, '')}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <FadeInView
      style={[
        {height: screenHeight},
        theme.background.lightGrey,
        theme.flex.col,
      ]}>
      <DetailNavTitle
        title={i18n.t('notification.title')}
        hideAmount
        hideServer
        onBack={goBack}
      />
      <View
        style={[
          theme.flex.row,
          theme.fill.fillW,
          theme.position.rel,
          theme.background.white,
        ]}>
        <NativeTouchableOpacity
          style={tabItemButtonStyle}
          onPress={() => handleChange(0)}>
          <View style={[theme.flex.center]}>
            {activeTitleRender(
              tabIndex === 0,
              i18n.t('notification.announcements'),
              countInfo.sysUserMessageCount,
            )}
          </View>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          style={tabItemButtonStyle}
          onPress={() => handleChange(1)}>
          <View style={[tabStyle.borderLeft, theme.flex.center]}>
            {activeTitleRender(
              tabIndex === 1,
              i18n.t('notification.systemMessage'),
              countInfo.sysMessageCount,
            )}
          </View>
        </NativeTouchableOpacity>
        <Animated.View
          style={[
            theme.background.primary,
            theme.position.abs,
            tabStyle.indicator,
            indicatorStyle,
          ]}
        />
      </View>

      <FlatList
        style={[theme.flex.flex1]}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setRefreshing(true);
              refreshNotification(false);
            }}
          />
        }
        contentContainerStyle={[theme.padding.l]}
        ListEmptyComponent={
          <View style={[theme.padding.xxl]}>
            <NoData />
          </View>
        }
        onEndReached={refreshNextPage}
        data={listData}
        renderItem={renderItem}
        ListFooterComponent={
          listData &&
          listData.length > 0 &&
          pageNo.current >= totalPage.current ? (
            <NoMoreData />
          ) : undefined
        }
      />
    </FadeInView>
  );
};

export default NotificationPage;
